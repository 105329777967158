/* || RESET */

:root {
  background-color: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--color1);
  background-color: var(--color0);
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* LoadingSpinner.css */
.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 4px solid grey;
  border-radius: 50%;
  border-top: 4px solid white;
  width: 50px;
  height: 50px;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
  background-color: black;
  font-size: 75px;
  color: white; /* Adjust color as needed */
}

.seccion {
  max-width: 600px;
  padding-top: 50px;
}

.title {
  text-align: center;
  margin: 1em 0 0.5em 0;
  font-size: 2em;
}

.navbar {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
}

/* Basic styling for the data component container */
.data-component {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

/* Styling for each data line within the data component */
.data-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  /* Border for each data line */
  border-radius: 4px;
}

.data-line .fa-check {
  color: #28a745 !important;
  /* Green color for the check icon */
  cursor: pointer;
  margin-left: 5px;
}

/* Styling for the left side of the data line */
.data-left {
  font-weight: bold;
}

/* Styling for the right side of the data line */
.data-right {
  color: #007bff;
  /* Adjust the color as needed */
  margin-left: auto;
  /* Align to the right end */
  margin-right: 10px;
}


.edit-input {
  width: 40%;
  padding: 8px;
  border: 1px solid #ddd;
  /* Border for the input */
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 5px;
}


.clear-all-div {
  display: flex;
  justify-content: center;
}

.clear-all {
  display: inline-block;
  padding: 15px 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #ffffff;
  background-color: var(--color3);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin: 1em;
}

.clear-all:hover {
  background-color: var(--color1);
}

.clear-all-x {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-weight: 900;
  border: 2px solid transparent;
  color: red;
  text-align: center;
  font-size: 100%;
  padding: 5px;
  cursor: pointer;
}

.clear-all:hover {
  background-color: var(--color2)
}

.clear-all:active {
  background-color: var(--color2);
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

section {
  margin: 1em;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: 600;
}

.th,
.server {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.margin-top {
  margin-top: 2em;
}

.margin-bottom {
  margin-bottom: 2em;
}

.thirty-three {
  width: 19%;
  text-align: center;
  margin: 0.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-label {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inline-label-small {
  margin: 0 0.5em 0 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.money-input,
.pizza-input,
.tips-input {
  margin: 1em auto;
  height: 40px;
  width: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.money-input:focus,
.pizza-input:focus,
.tips-input:focus {
  border-color: #2980b9;
}

.cash-input {
  margin: 0.5em auto;
  padding: 0.5em;
  height: 1em;
  width: 3em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 1.5em;
}

.server-name-width {
  width: 50%;
}

.cash-input-small {
  margin: 0.5em auto;
  height: 40px;
  width: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.cash-input-small:focus {
  border-color: #2980b9;
}

.nameinput {
  height: 3em;
  font-size: 120%;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.nameinput:focus {
  border-color: #2980b9;
}

.inline {
  display: flex;
}

.info {
  background-color: blue;
  border-radius: 50px;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5em 0;
  cursor: pointer;
  color: white;
}

.info-red {
  background-color: red;
  border-radius: 50px;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5em 0;
  cursor: pointer;
  color: white;
}

.info-message {
  font-style: italic;
  text-align: center;
  margin: 0 2em 0 2em;
  border: 3px double var(--color1);
  ;
  border-radius: 20px;
  padding: 1em;
  background-color: var(--color1);
  color: var(--color0);
}

input {
  background-color: var(--color1);
  color: var(--color0)
}

.pizza {
  display: none;
}

.pmserv {
  margin-left: 2em;
}

.servers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.instructors {
  display: flex;
  flex-direction: column;
}

.instructor {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.instructor-detail {
  display: flex;
  margin: 1em;
  align-items: center;
}

.instructor-detail>div,
.instructor-detail>input {
  flex: 1;
  text-align: center;
  width: 33.33%;
  /* Each div (or input) takes up 33.33% of the container width */
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.instructor-detail-input:focus {
  border-color: #2980b9;
}


.instructor-detail>div:first-child {
  text-align: left;
}

.instructor-detail-input {
  height: 2.5em;
}

.warning-message {
  background-color: #ffc107;
  color: #721c24;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ffc107;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
}

.warning-message-inside {
  background-color: #ffc107;
  color: #721c24;
  margin: 0.5em 0 0.5em 0;
}

.error-message {
  font-style: italic;
  text-align: center;
  border: 3px double var(--color1);
  border-radius: 20px;
  padding: 1em;
  background-color: var(--color1);
  color: var(--color0);
  font-size: small;
  display: flex;
  align-items: center;
}

/* Toggle button */

.container {
  position: absolute;
  top: 2%;
  left: 5%;
  width: 20px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 26px;
  background-color: #ccc;
  border-radius: 13px;
  transition: background-color 0.3s;
}

.toggle-label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
  background-color: black;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-input:checked+.toggle-label {
  background-color: #333;
}

.toggle-input:checked+.toggle-label:before {
  transform: translateX(24px);
  background-color: white;
}

/* Payment Terminal Match */

.card {
  display: flex;
  margin-top: 1em;
}

.card>div {
  flex: 1;
  /* Each card takes up equal space */
  margin-right: 10px;
}

.cardtotal {
  margin-right: 0;
  /* Remove margin for the last card */
}

.total-card {
  margin: 0.5em auto;
  height: 40px;
  width: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  border: 2px solid #3498db;
  border-radius: 5px;
}

@media only screen and (max-width: 400px) {
  * {
    font-size: 0.9rem;
  }

  .clear-all {
    font-size: 14px;
  }

  .error-message {
    font-size: 0.5em;
  }

  .card {
    display: block;
  }
}

.color-toggle {
  position: absolute;
  top: 2%;
  left: 5%;

}

.brush {
  position: absolute;
  top: 2%;
  left: 5%;
  width: 20px;
}

.colors {
  position: absolute;
  top: 6%;
  left: 5%
}

.color {
  border-radius: 50px;
  margin-top: 1em;
  width: 1.5em;
  height: 1.5em;
}

.black {
  background-color: black;
  color: black;
  --color0: black;
  --color1: white;
  --color2: rgb(43, 43, 43);
  --color3: #4025b9;
}

.white {
  background-color: white;
  color: white;
  --color0: white;
  --color1: black;
  --color2: rgb(43, 43, 43);
  --color3: #4025b9;
}

.black-line {
  border: 1px solid black;
}

.white-line {
  border: 1px solid white;
}

.pizza-making-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.yes-no {
  display: flex;
  flex-direction: row;
}

.red-line {
  color: red;
}

.switch {
  margin: 0px 10px 0px 10px;
}

.data-component {
  text-align: center;
}

/* TOGGLE BUTTON */


.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 4px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: black;
  -webkit-transition: .4s;
  transition: .4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 3px;
}

input:checked+.slider {
  background-color: white;
}

input:focus+.slider {
  box-shadow: 0 0 4px grey;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#round {
  border-radius: 34px;
}

#round:before {
  border-radius: 50%;
}

.print-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 5em;
  padding-top: 2em;
}

.padding-bottom {
  padding-bottom: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.print-line {
  margin: 0.3em 0em 0.3em 0em
}


.print-server {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.server-details {
  margin: 0.25rem 0rem 0.25rem 0rem;
}

.server-details:nth-child(1) {
  flex: 0 0 45%;
}

.server-details:nth-child(2) {
  flex: 0 0 15%;
}

.server-details:nth-child(3) {
  flex: 0 0 40%;
}


/* ALL CSS AM */

.AmButton,
.PmButton {
  background-color: var(--color1);
  width: 5.6em;
  height: 2.4em;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
  border-radius: 34px;
}

.PmButton {
  padding: 0.5em;
}

.word {
  background-color: var(--color1);
  margin-right: 0.5em;
  margin: 0em 0.5em 0em 0.1em;
  font-weight: 700;
  color: var(--color0);
}

.wordpm {
  margin: 0em 0.1em 0em 0.5em;
}

.circle {
  border-radius: 50px;
  width: 1.9em;
  height: 1.9em;
  color: var(--color0);
  ;
  background-color: var(--color0);
  margin-right: 0.1em;
}



/* FRONTPAGE */

.app-container {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #3498db;
  flex-direction: column;
}

.rectangle img {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  transform: rotate(-25deg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-size: cover;
  transition: transform 1s ease-in-out;
}

.rectangle img:hover {
  transform: scale(1.1) rotate(0deg);
  transition: transform 1s ease-in-out;
}

.shift {
  margin-bottom: 3em; /* Adjust margin to ensure footer is always below shift */
}

.am,
.pm {
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 1em 2em;
  border-radius: 10px;
  background-color: #005689;
  box-shadow: 0 0 20px rgba(0, 0, 0, 1);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
}

.am {
  margin-right: 3em;
}

.pm {
  margin-left: 3em;
}

.am:hover,
.pm:hover {
  box-shadow: 0 0 50px rgba(0, 0, 0, 1);
}

.by img {
  width: 75px;
  height: auto;
  display: block;
  border-radius: 50%;
}

/* Frontpage footer */

.home-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  color: white;
  padding: 1em;
  text-align: center;
}

.footerp {
  /* margin-bottom: 1em; */
}

.home-ext-link {
  color: white;
}

/* For smaller screens */
@media screen and (max-width: 768px) {
  .home-footer {
    /* position: static; */
    padding: 1em; /* Adjust padding as needed */
  }

  .footerp, .home-ext-link {
    /* display: block; */
    margin-bottom: 1em; /* Adjust spacing between elements on smaller screens */
  }
}

/* For wider screens */
@media screen and (min-aspect-ratio: 16/9) {
  .home-footer {

  }

  .shift {

  }
}

@media (max-width: 500px),
(max-height: 650px) {
  .rectangle img {
    width: 150px;
    height: 150px;
  }

  .am,
  .pm {
    padding: 1em 1.5em;
    font-size: 0.8em;
  }

  .by img {
    width: 50px;
  }
}


@media (max-height: 400px) {
  .rectangle img {
    width: 100px;
    height: 100px;
  }

  .am,
  .pm {
    padding: 1em 1.5em;
    font-size: 0.8em;
  }

  .by img {
    width: 50px;
  }
}

@media (max-height: 300px),
(max-width: 300px) {
  .rectangle img {
    width: 75px;
    height: 75px;
  }

  .by img {
    width: 30px;
  }
}